//==============================================================================
// Catalog Checker
//
// Checks if selected catalog matches listed catalogs in Global Configuration
// and displays a banner.
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';

import { RichTextComponent } from '@msdyn365-commerce/core';

import { ICatalogCheckerProps } from './catalog-checker.props.autogenerated';

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'catalog-checker';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * CatalogChecker component
 * @extends {React.PureComponent<ICatalogCheckerProps<ICatalogCheckerData>>}
 */
//==============================================================================
class CatalogChecker extends React.PureComponent<ICatalogCheckerProps<{}>> {
    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { config, context } = this.props;
        const { text, link, className } = config;

        const catalogs: string[] = context.app.config.baseCatalogs;
        const catalogId = this.props.context.request.user.catalogId;
        const isIncluded = !!catalogs?.find(catalog => catalog === catalogId);

        // If base catalogs are not set up, catalog is not assigned to user, or assigned catalog is a base catalog
        if (!catalogs?.length || !catalogId || catalogId === '-1' || isIncluded) {
            // Returning null seems to cause issues
            return <></>;
        }

        return (
            <div className={classnames(BASE_CLASS, className)}>
                {text && <RichTextComponent text={text} className={cls('text')} />}
                {link &&
                    <a
                        className={cls('link')}
                        href={link.linkUrl?.destinationUrl}
                        title={link.linkText}
                        aria-label={link.ariaLabel}
                        target={link.openInNewTab ? '_blank' : undefined}
                        rel='noreferrer'
                        role='button'
                    >
                        {link.linkText}
                    </a>
                }
            </div>
        );
    }
}

export default CatalogChecker;
